<template>
  <div>
    <el-dialog
      title=""
      :show-close="false"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="600px"
    >
      <div class="examRule_head flex">
        <span>带看反馈</span>
        <i class="el-icon-circle-close" @click="dialogVisible = false"></i>
      </div>
      <div class="bandLook">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
          <el-form-item label="带看时间:" prop="time">
            <el-date-picker
              v-model="ruleForm.time"
              type="date"
              placeholder="选择日期"
              format="yyyy 年 MM 月 dd 日"
              value-format="timestamp"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="" label-width="0px" prop="remark">
            <el-input
              type="textarea"
              placeholder="请描述您的跟进记录"
              v-model="ruleForm.remark"
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="bandLook-footer center">
          <el-button type="primary" @click="onSubmit('ruleForm')"
            >提交</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ruleForm: {
        fang_type: null,
        fang_id: null,
        time: "",
        remark: "",
      },
      dialogVisible: false,
      rules: {
        time: [
          {
            type: "date",
            required: true,
            message: "请选择日期",
            trigger: "change",
          },
        ],
        remark: [
          { required: true, message: "请描述您的跟进记录", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$axios.zuDaiKans({
            ...this.ruleForm,
            time:this.ruleForm.time / 1000
          }).then((res) => {
            this.dialogVisible = false;
            this.$message({
              message: res.msg,
              type: "success",
            });
            for (const key in this.ruleForm) {
              this.ruleForm[key] = ''
            }
            this.$emit('success')
          });
        } else {
          return false;
        }
      });
    },
    open(fang_type,id) {
      this.ruleForm.fang_type = fang_type;
      this.ruleForm.fang_id = id;
      this.dialogVisible = true;
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog__header,
/deep/ .el-dialog__body {
  padding: 0 !important;
}
.bandLook {
  padding: 0 20px;
}
.el-form-item {
  margin-top: 30px;
  margin-bottom: 0;
}
.el-date-editor {
  width: 488px;
}
/deep/ .el-textarea__inner {
  height: 240px;
}
.bandLook-footer {
  padding: 60px 0;
  .el-button {
    width: 480px;
    height: 56px;
    border-radius: 28px;
  }
}
</style>
