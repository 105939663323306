<template>
  <div class="examRule">
    <el-dialog
      title=""
      :show-close="false"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="600px"
    >
      <div class="examRule_head flex">
        <span>录音列表</span>
        <i class="el-icon-circle-close" @click="close"></i>
      </div>
      <div class="record">
        <ul class="record_ul">
          <li v-for="(item,index) in list" :key="index">
            <div class="record_li_left">
              <img :src="item.image" alt="">
              <span>{{ item.name }}</span>
            </div>
            <div class="record_li_right" @click="platMp3(item)">
              <img src="../assets/image/houseing/record.png" alt="">
              <span>10″</span>
            </div>
          </li>
        </ul>
        <div class="record_footer">
          <el-button @click="close">查看联系方式(1次)</el-button>
        </div>
        <audio v-if="mp3" id="audio" ref="audio" @ended="handleNext">
          <source :src="mp3" type="audio/mpeg">
        </audio>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      mp3:'',//音频
      type:1,//1房源2客源
      dialogVisible: false,
      details: {}, //
      from:{
        fang_id:''
      },
      list:[],//
    };
  },
  methods: {
    handleNext () {
      this.$axios.soundsTing({id:this.id}).then(res=>{
        console.log(res);
      })
    },
    platMp3 (item) {
      this.mp3 = item.sound
      this.id = item.id
      setTimeout(() => {
        this.$refs.audio.play()
      }, 100);
    },
    submit () {
      this.$axios.fangMobileLookUpdate(this.from).then(res=>{
        this.$message({
          message: res.msg,
          type: "success",
        });
         this.dialogVisible = false
        this.from.text = ''
        this.$emit('succeed')
      })
    },
    //关闭
    close() {
      this.dialogVisible = false;
    },
    getList () {
      this.$axios.soundsLock(this.from).then(res=>{
        this.list = res.data
      })
    },
    //打开
    open(id) {
      this.from.fang_id = id
      // this.type = type
      // this.from.id = id
      // this.from.log_id = shou_mobile_id
      // this.$axios[type == 1 ? 'fangMobileLook' : 'checkTel']({id}).then((res) => {
      //   
      //   this.details = res.data
      //   this.from.log_id = res.data.id
      // });
      this.getList()
      this.dialogVisible = true;
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog__header,
/deep/ .el-dialog__body {
  padding: 0 !important;
}
/deep/ .el-dialog {
  border-radius: 8px;
  overflow: hidden;
}

.f-24 {
  font-size: 24px;
}
.record_ul {
  padding: 0 20px;
  li {
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #EBEBEB;
    .record_li_left {
      display: flex;
      align-items: center;
      img {
        width: 48px;
        height: 48px;
        margin-right: 20px;
        border-radius: 50%;
      }
      span {
        color: #333333;
        font-size: 18px;
      }
    }
    .record_li_right {
      width: 110px;
      height: 40px;
      display: flex;
      cursor: pointer;
      align-items: center;
      justify-content: center;
      background: #E3EBFF;
      border-radius: 20px;
      img {
        width: 22px;
        height: 18px;
        margin-right: 20px;
      }
      span {
        color: #3273F6;
        font-size: 18px;
      }
    }
  }
}
.record_footer {
  padding: 60px;
  /deep/ .el-button {
    width: 100%;
    height: 56px;
    padding: 0;
    line-height: 56px;
    color: #FFFFFF;
    font-size: 18px;
    text-align: center;
    background: #3273F6;
    border-radius: 28px;
  }
}

</style>
