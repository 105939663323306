<template>
  <div class="examRule">
    <el-dialog
      title=""
      :show-close="false"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="960px"
    >
      <div class="examRule_head flex">
        <span>{{ details.xiaoqu_name }}</span>
        <i class="el-icon-circle-close" @click="close"></i>
      </div>
      <div class="p-l-30 p-r-20 p-t-20 p-b-20 view-pop">
        <div class="more-pop">
          <div class="more-box">
            <div class="tit m-b-10">基础信息</div>
            <ul class="more-ul">
              <li class="w48">
                <div class="label">房屋用途：</div>
                <div class="c">{{ details.fang_yongtu }}</div>
              </li>
              <li class="w48">
                <div class="label">附近地铁：</div>
                <div class="c">{{ details.ditie }}</div>
              </li>
              <li class="w48">
                <div class="label">房龄：</div>
                <div class="c">{{ details.fang_ling }}</div>
              </li>
              <li class="w48">
                <div class="label">房屋性质：</div>
                <div class="c">{{ details.fang_gou }}</div>
              </li>
              <li class="w48">
                <div class="label">最早入住时间：</div>
                <div class="c">{{ details.fang_zhu }}</div>
              </li>
              <li class="w48">
                <div class="label">装修时间：</div>
                <div class="c">{{ details.fang_xiu_time }}</div>
              </li>
              <li class="w48">
                <div class="label">地址：</div>
                <div class="c">{{ details.fang_address }}</div>
              </li>
              <li class="w48">
                <div class="label">梯户：</div>
                <div class="c">{{ details.fang_tihu }}</div>
              </li>
              <li class="w48">
                <div class="label">看房方式：</div>
                <div class="c">{{ details.fang_kan }}</div>
              </li>
              <li class="w48">
                <div class="label">物业费单价：</div>
                <div class="c">{{ estate.wuye_price }}</div>
              </li>
              <li class="w48">
                <div class="label">每月物业费：</div>
                <div class="c">{{ details.wuye_price_yue }}元/月</div>
              </li>
              <li class="w48">
                <div class="label">电费单价：</div>
                <div class="c">{{ estate.dian_price }}元/度</div>
              </li>
              <li class="w48">
                <div class="label">燃气单价：</div>
                <div class="c">{{ estate.ranqi_price }}元/m³</div>
              </li>
              <li class="w48">
                <div class="label">暖气单价：</div>
                <div class="c">{{ estate.nuanqi_price }}元/m³</div>
              </li>
              <li class="w48">
                <div class="label">水费单价：</div>
                <div class="c">{{ estate.shui_price }}元/t</div>
              </li>
              <li class="w48">
                <div class="label">物业电话：</div>
                <div class="c">{{ estate.wuye_mobile }}</div>
              </li>
              <li class="w48">
                <div class="label">小区交房时间：</div>
                <div class="c">{{ details.fang_jiao }}</div>
              </li>
              
              <li class="w48" v-if="type == 2">
                <div class="label">可带小孩：</div>
                <div class="c">{{ details.du_hai }}</div>
              </li>
              <li class="w48" v-if="type == 2">
                <div class="label">可带宠物：</div>
                <div class="c">{{ details.du_pets }}</div>
              </li>
              <li class="w48">
                <div class="label">车位：</div>
                <div class="c">{{ estate.che_que }}</div>
              </li>
              <li class="w48">
                <div class="label">车位月管理费：</div>
                <div class="c">{{ estate.che_guan_price }}</div>
              </li>
              <li class="w48">
                <div class="label">每月车位费：</div>
                <div class="c">{{ estate.che_price }}</div>
              </li>
              <li class="w48" v-if="type == 2">
                <div class="label">付款方式：</div>
                <div class="c">{{ details.price_type }}</div>
              </li>
              <li class="w48" v-if="type == 2">
                <div class="label">租期到期时间：</div>
                <div class="c">{{ details.zu_time }}</div>
              </li>
              <li class="w48">
                <div class="label">业主姓名：</div>
                <div class="c">{{ details.yezhu_name }}</div>
              </li>
              <li class="w48">
                <div class="label">房屋标签：</div>
                <div class="c">{{ details.fang_biaoqian && details.fang_biaoqian.join('、') }}</div>
              </li>
            </ul>
          </div>
          <div class="more-box">
            <div class="tit m-b-10">产权信息</div>
            <ul class="more-ul">
              <li class="w100">
                <div class="label">产权人：</div>
                <div class="c">{{ details.fang_chanquan }}</div>
              </li>
              <li class="w48">
                <div class="label">产权人证件类型：</div>
                <div class="c">{{ details.fang_chanquan_type }}</div>
              </li>
              <li class="w48">
                <div class="label">证件号：</div>
                <div class="c">{{ details.fang_chanquan_type_hao }}</div>
              </li>
              <li class="w48">
                <div class="label">权属证书类型：</div>
                <div class="c">{{ details.fang_quanshu_type }}</div>
              </li>
              <li class="w48">
                <div class="label">权属证书号码：</div>
                <div class="c">{{ details.fang_quanshu_type_hao }}</div>
              </li>
              <li class="w48">
                <div class="label">房产证图片：</div>
                <div class="flexs">
                  <img
                  :key="index"
                  v-for="(item,index) in details.fang_chancheng_images"
                    class="more-pop-img m-r-10"
                    :src="item"
                  />
                </div>
              </li>
              <li class="w48">
                <div class="label">身份证反正面图片：</div>
                <div class="flexs">
                  <img
                  :key="index"
                  v-for="(item,index) in details.identity_images"
                    class="more-pop-img m-r-10"
                    :src="item"
                  />
                </div>
              </li>
              <li class="w48">
                <div class="label">委托书图片：</div>
                <div class="flexs">
                  <img
                  :key="index"
                  v-for="(item,index) in details.weituo_images"
                    class="more-pop-img m-r-10"
                    :src="item"
                  />
                </div>
              </li>
            </ul>
          </div>
          <div class="more-box">
            <div class="tit m-b-15">户型</div>
            <div class="more-shadow" v-if="details.fang_huxing">
              <img
                class="shadow-img"
                :src="details.fang_huxing.image"
              />
              <div class="txt">
                <div class="t">{{ details.fang_huxing.huxing }}</div>
                <div class="c">{{ details.fang_huxing.mianji }}m²/{{ details.fang_huxing.chaoxiang }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id:null,//
      type:1,//1出售2出租
      details:{},//
      estate:{},//小区信息
      dialogVisible: false
    };
  },
  methods: {
    //关闭
    close() {
      this.dialogVisible = false;
    },
    getDetails () {
      this.$axios[this.type == 1 ? 'houseDetail' : 'houseZuDetail']({ id: this.id }).then((res) => {
        this.details = res.data;
        this.$axios.xiaoQuDetail({id:res.data.xiaoqu_id}).then(res=>{
          this.estate = res.data
        })
        this.dialogVisible = true;
      });
    },
    //打开
    open(id,type) {
      this.id = id
      this.type = type
      this.getDetails();
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog__header,
/deep/ .el-dialog__body {
  padding: 0 !important;
}
/deep/ .el-dialog {
  border-radius: 8px;
  overflow: hidden;
}

// .examRule_main {
//   // line-height: 28px;
// }
.f-24 {
  font-size: 24px;
}
.view-pop {
  .view-text {
    background: #f5f5f5;
    border-radius: 8px;
    padding: 10px;
  }
  /deep/ .view-text .el-textarea__inner {
    border: none;
    outline: none;
    background: none;
    font-size: 18px;
  }
}
.more-pop {
  .more-box {
    padding: 15px 0px;
  }
  .tit {
    font-size: 18px;
  }
  .more-ul {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .more-ul li {
    margin: 5px 0px;
    display: flex;
    font-size: 16px;
    line-height: 24px;
  }
  .more-ul li .c {
    line-height: 1.6;
  }
  .w48 {
    width: 48%;
  }
  .w100 {
    width: 100%;
  }
  .more-pop-img {
    width: 116px;
    height: 116px;
    border-radius: 4px;
  }
  .more-ul li .label {
    color: #999;
    white-space: nowrap;
    line-height: 1.6;
  }
  .more-shadow {
    box-shadow: 0px 0px 6px 0px rgba(189, 189, 189, 0.16);
    border-radius: 4px;
    padding: 20px;
    display: flex;
    display: flex;
    .shadow-img {
      width: 120px;
      height: 90px;
      border: 2px solid #ebebeb;
    }
    .txt {
      margin-left: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .t {
        font-size: 20px;
        line-height: 1.6;
      }
      .c {
        font-size: 18px;
        color: #666;
        line-height: 1.6;
      }
    }
  }
}
.interview_main_footer {
  padding: 40px 0 40px 0;
  /deep/ .el-button {
    width: 480px;
    height: 56px;
    color: #fff;
    font-size: 18px;
    background: #3273f6;
    border-radius: 28px;
  }
}
</style>
