<template>
  <div class="examRule">
    <el-dialog
      title=""
      :show-close="false"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="600px"
    >
      <div class="examRule_head flex">
        <span>楼栋号跟进反馈</span>
        <i class="el-icon-circle-close" @click="close"></i>
      </div>
      <div class="p-l-20 p-r-20 p-t-20 p-b-20 view-pop">
        <div class="phone-pop">
          <div class="phone-item">
            <div class="hd flex">
              <img class="phone-img" src="../assets/image/houseing/rule1.jpg" />
              <div class="txt flex flex-between">
                <div class="name">陈敏敏</div>
                <div class="date m-t-5">2020-04-12</div>
              </div>
            </div>
            <div class="article m-t-10">
              感觉性价比挺高的，现在在售的一期卖完了，现在又加推了精装修的现在在售的一期卖完了，现在又加推了精装修的
            </div>
          </div>
          <div class="phone-item">
            <div class="hd flex">
              <img class="phone-img" src="../assets/image/houseing/rule1.jpg" />
              <div class="txt flex flex-between">
                <div class="name">陈敏敏</div>
                <div class="date m-t-5">2020-04-12</div>
              </div>
            </div>
            <div class="article m-t-10">
              感觉性价比挺高的，现在在售的一期卖完了，现在又加推了精装修的现在在售的一期卖完了，现在又加推了精装修的
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      content: "", //借还记录
    };
  },
  methods: {
    //关闭
    close() {
      this.dialogVisible = false;
    },
    //打开
    open(id) {
      this.$axios.fangLouLook({id}).then((res) => {
        this.dialogVisible = true;
        this.content = res.data.content;
      });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog__header,
/deep/ .el-dialog__body {
  padding: 0 !important;
}
/deep/ .el-dialog {
  border-radius: 8px;
  overflow: hidden;
}

// .examRule_main {
//   // line-height: 28px;
// }
.f-24 {
  font-size: 24px;
}
.view-pop {
  .view-text {
    background: #f5f5f5;
    border-radius: 8px;
    padding: 10px;
  }
  /deep/ .view-text .el-textarea__inner {
    border: none;
    outline: none;
    background: none;
    font-size: 18px;
  }
}
.phone-pop {
  .phone-item {
    padding: 10px 0px;
    .hd {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .phone-img {
      display: block;
      width: 40px;
      height: 40px;
      border-radius: 100%;
    }
    .txt {
      margin-left: 10px;
      width: calc(100% - 50px);
    }
    .name {
      font-size: 16px;
    }
    .date {
      font-size: 12px;
      color: #999;
    }
    .article {
      font-size: 14px;
      color: #666;
      line-height: 20px;
    }
  }
}
.interview_main_footer {
  padding: 40px 0 40px 0;
  /deep/ .el-button {
    width: 480px;
    height: 56px;
    color: #fff;
    font-size: 18px;
    background: #3273f6;
    border-radius: 28px;
  }
}
</style>
