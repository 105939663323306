<template>
  <div class="examRule">
    <el-dialog
      title=""
      :show-close="false"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="780px"
    >
      <div class="examRule_head flex">
        <span>户型介绍</span>
        <i class="el-icon-circle-close" @click="close"></i>
      </div>
      <div class="p-l-20 p-r-20 p-t-20 p-b-20 view-pop">
        <div class="hx-pop">
          <div class="hd">
            <div @click="changeHeadIndex(index)" class="h-li m-r-20" :class="{active:index == headIndex}" v-for="(item,index) in houseList" :key="index">{{ item.name }}</div>
          </div>
          <ul class="introduce-ul m-t-20">
            <li v-for="(item, index) in huxingList" :key="index">
              <div class="li-con">
                <div class="li-img">
                  <img :src="item.image" />
                </div>
                <div class="li-txt">
                  <div class="li-c">{{ item.huxing }}</div>
                  <div class="li-c">面积{{ item.mianji }}m²/{{ item.chaoxiang }}</div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id:null,//
      dialogVisible: false,
      houseList: [], //户型列表
      headIndex:0,//
      huxingList:[],
    };
  },
  methods: {
    changeHeadIndex (index) {
      this.headIndex = index
      this.gethuXing()
    },
    //关闭
    close() {
      this.dialogVisible = false;
    },
    //打开
    open(id) {
      this.id = id
      this.$axios.huXing().then((res) => {
        this.dialogVisible = true;
        this.houseList = res.data;
        this.gethuXing()
      });
    },
    //获取户型
    gethuXing () {
      this.$axios.xiaoQuHuXing({id:this.id,type:this.houseList[this.headIndex].name}).then(res=>{
        this.huxingList = res.data
      })
    }
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog__header,
/deep/ .el-dialog__body {
  padding: 0 !important;
}
/deep/ .el-dialog {
  border-radius: 8px;
  overflow: hidden;
}

// .examRule_main {
//   // line-height: 28px;
// }
.f-24 {
  font-size: 24px;
}
.view-pop {
  .view-text {
    background: #f5f5f5;
    border-radius: 8px;
    padding: 10px;
  }
  /deep/ .view-text .el-textarea__inner {
    border: none;
    outline: none;
    background: none;
    font-size: 18px;
  }
}
.hx-pop {
  .hd {
    display: flex;
    flex-wrap: wrap;
    .h-li {
      min-width: 50px;
      padding: 0px 10px;
      background: #f5f5f5;
      border-radius: 4px;
      display: flex;
      height: 32px;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: 16px;
      color: #666;
      cursor: pointer;
    }
    .active {
      background: #d4e3ff;
      color: #3273f6;
    }
  }
  .introduce-ul {
    display: flex;
    flex-wrap: wrap;
    li {
      width: 50%;
    }
    .li-con {
      margin: 10px 10px 10px 10px;
    }
    .li-img {
      border: 1px solid #ebebeb;
    }
    .li-img img {
      display: block;
      width: 100%;
    }
    .li-txt {
      margin: 10px 0px 0 0;
    }
    .li-c {
      font-size: 18px;
      color: #666;
      line-height: 1.6;
    }
  }
}
.interview_main_footer {
  padding: 40px 0 40px 0;
  /deep/ .el-button {
    width: 480px;
    height: 56px;
    color: #fff;
    font-size: 18px;
    background: #3273f6;
    border-radius: 28px;
  }
}
</style>
