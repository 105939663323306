<template>
  <div class="">
    <el-dialog :show-close="false" :visible.sync="dialogVisible" width="704">
      <div class="" slot="title">
        <div class="header">
          <div class="">谦祥世茂万华城</div>
        </div>
      </div>
      <div class="select-search flex">
        <div class="select-search-ipt">
          <el-input v-model="ruleFrom.search" placeholder="请输入搜索内容"></el-input>
        </div>
        <div class="select-search-btn center" @click="getList">搜索</div>
      </div>
      <div class="select-main">
        <div class="infinite-list-wrapper" style="overflow: auto">
          <ul
            class="list"
            v-infinite-scroll="load"
            infinite-scroll-disabled="disabled"
          >
            <li @click="changeIndex(index)" v-for="(item,index) in list" :key="item.id" class="list-item">
              <div class="select center">
                
                <img v-if="index == selectIndex" src="../assets/image/publice/select-pink.png" alt="">
                <img v-else src="../assets/image/publice/select.png" alt="">
              </div>
              <div class="">
                <div class="flex li-top">
                  <div class="flexs">
                    <div class="name">{{ item.user_name }}</div>
                    <div class="sex">{{ item.sex }}</div>
                    <div class="class center">{{ getClass(item.booth_type) }}</div>
                    <div class="fitment center">{{ item.finish_type }}</div>
                  </div>
                  <div class="flexs">
                    <div>预算</div>
                    <div>
                      {{ item.min_total_price }}-{{ item.max_total_price }}元/月
                    </div>
                  </div>
                </div>
                <div class="ul">
                  <div class="li">
                    <div class="">意向类型：</div>
                    <div class="">租房</div>
                  </div>
                  <div class="li">
                    <div class="">经纪人：</div>
                    <div class="">{{ item.user_name }}</div>
                  </div>
                  <div class="li">
                    <div class="flex-shrink">意向位置：</div>
                    <div class="">{{ item.city }}</div>
                  </div>
                  <div class="li">
                    <div class="">最近带看日期：</div>
                    <div class="">{{ item.look_date }}</div>
                  </div>
                  <div class="li">
                    <div class="">录入日期：</div>
                    <div class="">{{ item.createtime }}</div>
                  </div>
                  <div class="li">
                    <div class="">客户来源：</div>
                    <div class="">{{ item.client_source }}</div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="footer center">
        <div class="footer-btn center" @click="submit">提交</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    // 使用对象展开运算符将 getter 混入 computed 对象中
    ...mapGetters(["userInfo"]),
  },
  data() {
    return {
      selectIndex:-1,
      dialogVisible: false,
      list: [],
      loading: false,
      
      ruleFrom:{
        search:'',
        id: null, //房源id
      },
      from: {
        fang_id:'',
        number: "",
        image: "",
        fang_type: "",
        kehu_name: "",
        kehu_mobile: "",
        start_time: "",
        end_time: "",
        price: "",
        text: "",
      },
    };
  },
  methods: {
    //提交
    submit () {
      if (this.selectIndex == -1) return this.$message('请选择客户');
      this.$axios.houseContract(this.from).then(res=>{
        this.dialogVisible = false
        this.$message({
          message: res.msg,
          type: 'success'
        });
        this.$emit('succeed')
      })
    },
    changeIndex (index) {
      this.selectIndex = index
      this.from.kehu_name = this.list[index].name
      this.from.kehu_mobile = this.list[index].mobile
    },
    load() {
      this.loading = true;
      setTimeout(() => {
        this.count += 2;
        this.loading = false;
      }, 2000);
    },
    open(data) {
      this.from = data
      this.getList();
      this.getDetails()
      this.dialogVisible = true
    },
    getList() {
      this.$axios
        .clientJ({
          broker_user_id: this.userInfo.id,
          search:this.ruleFrom.search
        })
        .then((res) => {
          this.list = res.data;
        });
    },
    getDetails () {
      this.$axios.houseZuDetail({id:this.from.fang_id}).then(res=>{
        console.log(res);
      })
    }
  }
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog__header,
/deep/ .el-dialog__body {
  padding: 0;
}
.header {
  display: flex;
  height: 56px;
  align-items: center;
  padding: 0 20px;
  justify-content: space-between;
  background: #ededed;
  border-radius: 8px 8px 0px 0px;
  img {
    width: 24px;
    height: 24px;
  }
  div {
    padding-left: 14px;
    color: #333333;
    font-size: 18px;
    position: relative;
    &::after {
      width: 4px;
      height: 24px;
      content: "";
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      position: absolute;
      background: #3273f6;
    }
  }
}
.select-search {
  padding: 20px 40px;
  .select-search-ipt {
    flex: 1;
    height: 48px;
    padding: 0 20px;
    border: 2px solid #ebebeb;
    border-right: none;
    img {
      width: 22px;
      height: 2px;
      margin-right: 10px;
    }
    /deep/ .el-input__inner {
      border: none;
      padding: 0;
      flex: 1;
    }
  }
  .select-search-btn {
    width: 120px;
    height: 48px;
    cursor: pointer;
    color: #ffffff;
    font-size: 18px;
    background: #3273f6;
  }
}
.infinite-list-wrapper {
  height: 500px;
  ul {
    padding: 0 40px;
    li {
      display: flex;
      padding: 20px;
      margin-bottom: 20px;
      background: #ffffff;
      box-shadow: 0px 0px 6px 0px rgba(189, 189, 189, 0.16);
      border-radius: 4px;
      .select {
        height: 28px;
        cursor: pointer;
        img {
          width:24px;
          height:24px;
        }
        margin-right:20px;
      }
      .li-top {
        margin-bottom: 5px;
        .name {
          font-size: 18px;
        }
        .sex {
          color: #666666;
          font-size: 16px;
          margin: 0 15px 0 20px;
        }
        .class {
          width: 48px;
          color: #3273f6;
          font-size: 14px;
          height: 28px;
          background: #d5e3ff;
          border-radius: 3px;
        }
        .fitment {
          width: 76px;
          height: 28px;
          color: #ff752a;
          margin-left: 10px;
          font-size: 14px;
          background: rgba(255, 117, 42, 0.1);
          border-radius: 3px;
        }
      }
      .ul {
        display: flex;
        flex-wrap: wrap;
        .li {
          width: 50%;
          display: flex;
          margin-top: 15px;
          div {
            color: #999999;
            font-size: 16px;
            &:first-child {
              flex-shrink: 0;
            }
            &:last-child {
              color: #333333;
            }
          }
        }
      }
    }
  }
}
.footer {
  height: 80px;
  background: #ffffff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16);
  .footer-btn {
    width: 480px;
    height: 56px;
    cursor: pointer;
    color: #ffffff;
    font-size: 18px;
    background: #3273f6;
    border-radius: 28px;
  }
}
</style>
