<template>
  <div class="examRule">
    <el-dialog
      title=""
      :show-close="false"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="380"
    >
      <div class="p-l-30 p-r-30 p-t-30 p-b-30 view-pop">
        <div class="done-pop">
          <div class="done-flex">
            <div class="li" @click="changeOk">
              <img
                  class="done-img"
                  src="../assets/image/houseing/house51.png"
                />
                <div class="t m-t-15">在线签约</div>
            </div>
            <div class="li" @click="download">
               <img
                  class="done-img"
                  src="../assets/image/houseing/house52.png"
                />
                <div class="t m-t-15">下载合同</div>
            </div>
          </div>
          <div class="done-close p-t-40 p-b-40">
            <img
              @click="close"
              class="done-close-img"
              src="../assets/image/houseing/close.png"
            />
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id:null,
      type:1,//
      dialogVisible: false
    };
  },
  methods: {
    changeOk () {
      this.$emit('success')
      this.dialogVisible = false
    },
    download () {
      this.$axios.downloadContract({contract_type:this.type}).then(res=>{
        location.href = res.data.file
        this.dialogVisible = false
      })
    },
    //关闭
    close() {
      this.dialogVisible = false;
    },
    //打开
    open(type,id) {
      this.id = id
      this.type = type
      this.dialogVisible = true;
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog__header,
/deep/ .el-dialog__body {
  padding: 0 !important;
}
/deep/ .el-dialog {
  border-radius: 8px;
  overflow: hidden;
}

// .examRule_main {
//   // line-height: 28px;
// }
.f-24 {
  font-size: 24px;
}
.view-pop {
  .view-text {
    background: #f5f5f5;
    border-radius: 8px;
    padding: 10px;
  }
  /deep/ .view-text .el-textarea__inner {
    border: none;
    outline: none;
    background: none;
    font-size: 18px;
  }
}
.done-pop {
  .done-flex {
    display: flex;
    .li {
      width: 50%;
      cursor: pointer;
      text-align: center;
    }
    .done-img {
      width: 68px;
      height: 68px;
      display: block;
      margin: 0 auto;
    }
    .t {
      font-size: 18px;
    }
  }
  .done-close {
    display: flex;
    justify-content: center;
  }
  .done-close-img {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
}
.interview_main_footer {
  padding: 40px 0 40px 0;
  /deep/ .el-button {
    width: 480px;
    height: 56px;
    color: #fff;
    font-size: 18px;
    background: #3273f6;
    border-radius: 28px;
  }
}
</style>
