<template>
  <div class="examRule" >
    <el-dialog
      title=""
      v-dialogDrag
      :show-close="false"
        :lock-scroll="false"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="600px"
    >
      <div class="examRule_head flex">
        <span>查看联系方式</span>
        <!-- <i class="el-icon-circle-close" @click="close"></i> -->
      </div>
      <div class="p-l-20 p-r-20 p-t-20 p-b-20 view-pop">
        <div class="f-24 color-3 m-t-20 m-b-40" v-if="type == 1">联系方式：{{ details.yezhu_mobile }}</div>
        <div class="f-24 color-3 m-t-20 m-b-40" v-if="type == 2">联系方式：{{ details.mobile }}</div>
        <div class="view-text">
          <el-input
            v-if="type == 1"
            type="textarea"
            placeholder="请描述您的具体联系方式"
            rows="5"
            v-model="from.text"
          ></el-input>
          <el-input
            v-if="type == 2"
            type="textarea"
            placeholder="请描述您的具体联系方式"
            rows="5"
            v-model="from.remark"
          ></el-input>
        </div>
        <div class="interview_main_footer center">
          <el-button @click="submit('form')">提交</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      type:1,//1房源2客源
      flag:0,//1出售2出租
      dialogVisible: false,
      details: {}, //
      from:{
        text:'',
        remark:'',
        id:'',
        look_id:'',
        ref_id:'',
        log_id:''
      }
    };
  },
  methods: {
    submit () {
      if (this.type == 1 && this.from.text.length < 5 || this.type == 2 && this.from.remark.length < 5) return this.$message({
          message: '至少输入五个字',
          type: 'warning'
        });
      this.$axios[this.type == 1 ? this.flag == 1 ? 'fangMobileLookUpdate' : 'fangZuMobileLookUpdate' : 'addGn'](this.from).then(res=>{
        this.$message({
          message: res.msg,
          type: "success",
        });
         this.dialogVisible = false
        this.from.text = ''
        this.from.remark = ''
        this.$emit('succeed')
      })
    },
    //关闭
    close() {
      this.dialogVisible = false;
    },
    //打开
    open(id,type,shou_mobile_id,flag) {
      this.flag = flag
      this.type = type
      this.from.id = id
      this.from.ref_id = id
      this.from.log_id = shou_mobile_id
      this.from.look_id = shou_mobile_id
      this.$axios[type == 1 ? this.flag == 1 ? 'fangMobileLook' : 'fangZuMobileLook' : 'checkTel']({id}).then((res) => {
        this.dialogVisible = true;
        this.details = res.data
        this.from.log_id = res.data.id || res.data.lookId
        this.from.look_id = res.data.id || res.data.lookId
      }).catch(err=>{
        this.$emit('close',err)
      })
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog__body {
  padding: 0 !important;
}
/deep/ .el-dialog {
  border-radius: 8px;
  overflow: hidden;
}

// .examRule_main {
//   // line-height: 28px;
// }
.f-24 {
  font-size: 24px;
}
.view-pop {
  .view-text {
    background: #f5f5f5;
    border-radius: 8px;
    padding: 10px;
  }
  /deep/ .view-text .el-textarea__inner {
    border: none;
    outline: none;
    background: none;
    font-size: 18px;
  }
}
.interview_main_footer {
  padding: 40px 0 40px 0;
  /deep/ .el-button {
    width: 480px;
    height: 56px;
    color: #fff;
    font-size: 18px;
    background: #3273f6;
    border-radius: 28px;
  }
}
</style>
