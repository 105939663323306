<template>
  <div class="examRule">
    <el-dialog
      title=""
      :show-close="false"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="600px"
    >
      <div class="examRule_head flex">
        <span>{{ from.type == 1 ? '手机号' : '楼栋号' }}跟进反馈</span>
        <i class="el-icon-circle-close" @click="close"></i>
      </div>
      <div class="p-l-20 p-r-20 p-t-20 p-b-20 view-pop">
        <div class="phone-pop">
          <div class="phone-item" v-for="(item) in list" :key="item.id">
            <div class="hd flex">
              <div class="h-l flex">
                <img
                  class="phone-img"
                  :src="item.image"
                />
                <div class="txt">
                  <div class="name">{{ item.name }}</div>
                  <div class="date m-t-5">{{ item.createtime }}</div>
                </div>
              </div>
              <img
                class="phone-icon"
                src="../assets/image/houseing/icon25.png"
              />
            </div>
            <div class="article m-t-10">{{ item.text }}</div>
          </div>
        </div>
        <div class="paging" v-if="list.length">
          <el-pagination
            background
            :page-size="5"
            @current-change="changePage"
            layout="prev, pager, next, jumper, ->, total"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      from:{
        page:1,
        id:'',
        type:1,
        pageNum:5
      },
      total:0,
      list:[]
    };
  },
  methods: {
    changePage (e) {
      this.from.page = e
      this.getList()
    },
    //关闭
    close() {
      this.dialogVisible = false;
    },
    getList () {
      this.$axios[this.from.type == 1 ? 'houseMobileLook' : 'houseLouLook'](this.from).then((res) => {
        this.list = res.data.data
        this.total = res.data.total
      });
    },
    //打开
    open(id,type) {
      this.from.id = id
      this.from.page = 1
      this.from.type = type
      this.dialogVisible = true;
      this.getList()
      
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog__header,
/deep/ .el-dialog__body {
  padding: 0 !important;
}
/deep/ .el-dialog {
  border-radius: 8px;
  overflow: hidden;
}

// .examRule_main {
//   // line-height: 28px;
// }
.f-24 {
  font-size: 24px;
}
.view-pop {
  .view-text {
    background: #f5f5f5;
    border-radius: 8px;
    padding: 10px;
  }
  /deep/ .view-text .el-textarea__inner {
    border: none;
    outline: none;
    background: none;
    font-size: 18px;
  }
}
.phone-pop {
  .phone-item {
    padding: 10px 0px;
    .hd {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .phone-img {
      display: block;
      width: 40px;
      height: 40px;
      border-radius: 100%;
    }
    .txt {
      margin-left: 10px;
    }
    .name {
      font-size: 16px;
    }
    .date {
      font-size: 12px;
      color: #999;
    }
    .phone-icon {
      width: 28px;
      height: 28px;
    }
    .article {
      font-size: 14px;
      color: #666;
      line-height: 20px;
    }
  }
}
.interview_main_footer {
  padding: 40px 0 40px 0;
  /deep/ .el-button {
    width: 480px;
    height: 56px;
    color: #fff;
    font-size: 18px;
    background: #3273f6;
    border-radius: 28px;
  }
}
</style>
