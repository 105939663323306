// 房源详情
<template>
  <div class="housing b-c-f">
    <div class="housing_box w-1200 margin-auto">
      <div class="housing_nav p-t-30 p-b-30">
        <div class="housing_nav_top flex m-b-40">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/housing?type=1' }"
              >房源管理</el-breadcrumb-item
            >
            <el-breadcrumb-item>租房</el-breadcrumb-item>
            <el-breadcrumb-item class="color_blue">详情</el-breadcrumb-item>
          </el-breadcrumb>
          <div class="housing_nav_time">上架时间：{{ details.createtime }}</div>
        </div>
        <div class="housing_nav_main flex">
          <div class="housing_nav_main_left flexs">
            <div class="housing_nav_name m-r-15">{{ details.xiaoqu_name }}</div>
            <ul class="housing_nav_ul flexs">
              <li style="margin-right: 40px" class="center f-bold color-ff">
                {{ details.fang_level }}
                <el-popover placement="bottom" trigger="click">
                  <div class="">
                    <el-select
                      v-model="details.fang_level"
                      placeholder="请选择房源等级"
                    >
                      <el-option
                        v-for="(item, index) in zuGradeList"
                        :key="index"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                    <div class="center m-t-10">
                      <el-button type="primary" @click="onSubmitlevel"
                        >提交</el-button
                      >
                    </div>
                  </div>
                  <small
                    style="
                      font-size: 12px;
                      position: absolute;
                      top: 50%;
                      right: -30px;
                      transform: translateY(-50%);
                    "
                    class="color-ff"
                    slot="reference"
                    >纠错</small
                  >
                </el-popover>
              </li>
              <li class="center m-r-10">
                {{ getStatus(details.fang_status) }}
              </li>
              <li @click="changeHei" class="center">
                <img
                  src="../../assets/image/houseing/defriend.png"
                  alt=""
                  v-if="details.hei == 0"
                />
              </li>
            </ul>
          </div>
          <div class="housing_nav_main_right flexs">
            <div class="housing_nav_coll flexs" @click="changeColl">
              <img
                src="../../assets/image/houseing/coll_pink.png"
                alt=""
                v-if="details.guanzhu == 1"
              />
              <img src="../../assets/image/houseing/coll.png" alt="" v-else />
              <span>关注</span>
            </div>
            <div class="housing_nav_number">编号：{{ details.id }}</div>
          </div>
        </div>
      </div>
      <div class="housing_head flex">
        <div class="housing_head_left">
          <div class="housing_head_left_video">
            <!-- <video
            v-if="details.fang_vediofile.length && details.fang_vediofile[0]"
              controls
              autoplay
              :src="details.fang_vediofile[0]"
            ></video> -->
            <Swiper class="swiper" ref="mySwiper1" :options="swiper">
              <SwiperSlide
                v-for="(item, index) in details.fang_lunbo_images"
                :key="index"
              >
                <!-- <img :src="item" alt="" /> -->
                
                <el-image
                  style="width: 100%; height: 100%"
                  :src="item"
                  :preview-src-list="details.fang_lunbo_images"
                >
                </el-image>
              </SwiperSlide>
              <SwiperSlide v-for="item in details.fang_vediofile" :key="item">
                <video controls :src="item"></video>
              </SwiperSlide>
              <div class="swiper-paginations" slot="pagination"></div>
              <div
                class="swiper-button-prev"
                @click="prev('mySwiper1')"
                slot="button-prev"
              ></div>
              <div
                class="swiper-button-next"
                @click="next('mySwiper1')"
                slot="button-next"
              ></div>
            </Swiper>
            <!-- <div class="marking center">
              <img src="../../assets/image/houseing/play.png" alt="" />
            </div> -->
          </div>
          <div class="housing_head_left_swiper m-t-10" v-show="isMore">
            <Swiper
              class="swiper swipers"
              ref="mySwiper"
              :options="swiperOption"
            >
              <SwiperSlide
                v-for="(item, index) in details.fang_lunbo_images"
                :key="index"
              >
                <el-image
                  style="width: 100%; height: 100%"
                  :src="item"
                  :preview-src-list="details.fang_lunbo_images"
                >
                </el-image>
                <!-- <img class="img" :src="item" /> -->
              </SwiperSlide>
              <div class="swiper-pagination" slot="pagination"></div>
              <div
                class="swiper-button-prev"
                slot="button-prev"
                @click="prev('mySwiper')"
              ></div>
              <div
                class="swiper-button-next"
                slot="button-next"
                @click="next('mySwiper')"
              ></div>
            </Swiper>
          </div>
          <ul class="housing_head_image" v-show="!isMore">
            <li v-for="(item, index) in details.fang_lunbo_images"
                :key="index">
              <img :src="item" alt="">
            </li>
          </ul>
          <div @click="isMore = !isMore" class="housing_head_left_more center">
            <div class="">更多展示</div>
            <img :class="{rotate:!isMore}" src="../../assets/image/houseing/bot.png" alt="">
          </div>
          <ul class="housing_head_left_ul flexs">
            <li class="flexs p-l-10 blue" @click="hrefEdit">
              <img src="../../assets/image/houseing/house33.png" alt="" />
              <span>编辑</span>
            </li>
            <li
              :class="{ gray: details.locking_status != 0 }"
              class="flexs p-l-10 orange"
              @click="lock"
            >
              <img
                v-if="details.locking_status == 0"
                src="../../assets/image/houseing/house34.png"
                alt=""
              />
              <img v-else src="../../assets/image/houseing/lock.png" alt="" />
              <span>{{
                details.locking_status == 0
                  ? "锁定"
                  : details.locking_status == 1
                  ? time
                  : "取消锁定"
              }}</span>
            </li>
            <li
              class="flexs p-l-10 yellow"
              :class="{ gray: details.locking_status == 0 }"
              @click="bargain"
            >
              <img
                src="../../assets/image/houseing/bargain.png"
                v-if="details.locking_status == 0"
                alt=""
              />
              <img
                src="../../assets/image/houseing/house35.png"
                v-else
                alt=""
              />
              <span>成交房源</span>
            </li>
            <li
              class="flexs p-l-10 red"
              @click="$refs.delete.open(id, details.fang_type)"
            >
              <img src="../../assets/image/houseing/house36.png" alt="" />
              <span>删除</span>
            </li>
          </ul>
        </div>
        <div class="housing_head_right">
          <div class="housing_head_price flexs">
            <div class="housing_head_price_txt f-1 flexs">
              <span
                >租赁价格<el-popover placement="top" trigger="click">
                  <el-form ref="form" :model="details" label-width="0px">
                    <el-form-item label="">
                      <el-input v-model="details.can_price"></el-input>
                    </el-form-item>
                    <div class="center">
                      <el-button type="primary" @click="onSubmitPrice"
                        >提交</el-button
                      >
                    </div>
                  </el-form>
                  <small style="font-size: 12px" class="" slot="reference"
                    >纠错</small
                  >
                </el-popover>
              </span>
              <span
                >{{ details.can_price }}元/{{ type == 1 ? "m²" : "月" }}</span
              >
            </div>
            <div class="f-1">
              <div class="color-ff f-bold">优质房源评分:{{ details.youzhi_fen }}分</div>
              <div class="m-t-10">维护人职责评分:{{ details.weihu_fen }}分</div>
              
            </div>
            <!-- <div class="housing_head_price_txt flexs">
              <span>{{ type == 1 ? "总价" : "买卖均价" }}</span>
              <span
                >{{ type == 1 ? details.zong_price : details.avg_price
                }}{{ type == 1 ? "万" : "元/㎡" }}</span
              >
            </div> -->
          </div>
          <div class="housing_head_lou flexs m-t-30 m-b-20">
            <div class="housing_head_lou_txt flexs f-1">
              <div class="housing_head_lou_number flexs">
                <span>楼栋号：</span>
                <span
                  >
                  <!-- {{ details.fang_status == 1 ? '********' : details.xiaoqu_loudong ? details.xiaoqu_loudong.split(',').join('-') : '' }} -->
                  {{ details.xiaoqu_loudongs ? details.xiaoqu_loudongs.split(',').join('-') : '' }}
                  <!-- v-if="details.fang_status == 1" -->
                  <el-popover placement="top" trigger="click">
                    <div class="">
                      <div class="flex m-b-10" style="width: 300px">
                        <el-input
                          placeholder="楼号"
                          style="width: 70px"
                          v-model="lou"
                        ></el-input>
                        <el-input
                          placeholder="单元"
                          style="width: 70px"
                          v-model="dan"
                        ></el-input>
                        <el-input
                          placeholder="几室"
                          style="width: 70px"
                          v-model="hao"
                        ></el-input>
                        <el-input
                          placeholder="朝向"
                          style="width: 70px"
                          v-model="orientation"
                        ></el-input>
                      </div>
                      <el-input
                          placeholder="修改原因"
                          v-model="yuanyin"
                        ></el-input>
                      <div class="center">
                        <el-button type="primary" @click="onSubmitFool"
                          >提交</el-button
                        >
                      </div>
                    </div>
                    <small
                      
                      style="font-size: 12px"
                      class="color-ff"
                      slot="reference"
                      >纠错</small
                    >
                  </el-popover>
                </span>
              </div>
              <!-- <div
                v-if="details.locking_status == 0 && details.fang_status == 1"
                class="housing_head_lou_look"
                @click="lookBuilding"
              >
                查看
              </div> -->
            </div>
            <div class="flexs f-1">
              
              <div class="housing_head_lou_txt flexs f-1">
              <div class="housing_head_lou_number flexs">
                <span>带看次数：</span>
                <span>{{ details.daikan_number }}次</span>
              </div>
            </div>
            <div class="housing_head_lou_txt flexs f-1">
              <div class="housing_head_lou_number flexs">
                <span>点击次数：</span>
                <span>{{ details.dian_number }}次</span>
              </div>
            </div>
            </div>
            
          </div>
          <ul class="housing_head_tag flexs flex-wrap">
            <li
              v-for="(item, index) in details.fang_biaoqian"
              :key="index"
              class="center"
            >
              {{ item }}
            </li>
          </ul>
          <ul class="housing_head_area flex m-t-30 m-b-30">
            <li>
              <span>{{ details.fang_mianji }}m²</span>
              <span>面积</span>
            </li>
            <li>
              <span>{{ details.fang_chaoxiang }}</span>
              <span>朝向</span>
            </li>
            <li>
              <span style="height: 22px">{{
                details.fang_huxing ? details.fang_huxing.huxing : ""
              }}</span>
              <span>户型</span>
            </li>
            <li>
              <span
                >{{ details.xiaoqu_louceng }}/总{{
                  details.zong_louceng
                }}层</span
              >
              <span>楼层</span>
            </li>
          </ul>
          <ul class="housing_head_time flexs flex-wrap">
            <li>
              <span>最近带看时间：</span>
              <span>{{ details.daikan_time }}</span>
            </li>
            <li>
              <span>是否有车位：</span>
              <span>{{ details.che_que }}</span>
            </li>
            <li>
              <span>梯户：</span>
              <span>{{ details.fang_tihu }}</span>
            </li>
            <li>
              <span>装修类型：</span>
              <span>{{ details.fang_xiu }}</span>
            </li>
          </ul>
          <ul
            class="
              housing_head_footer housing_head_time
              flexs
              m-b-40
              flex-wrap
              m-t-30
            "
          >
            <li>
              <span>看房方式：</span>
              <span>{{ isKanFangType ? details.fang_kan : '***' }}</span>
              <span
                @click="isKanFangType = !isKanFangType"
                class="color-ff"
                style="font-size: 12px; cursor: pointer"
                >{{ isKanFangType ? '隐藏' : '查看' }}</span
              >
            </li>
            <li>
              <span>付款方式：</span>
              <span>{{ details.price_type }}</span>
            </li>
            <li>
              <span>看房密码：</span>
              <span>{{ isKanPassword ? details.yaoshi_password : '***' }}</span>
              <span
                @click="isKanPassword = !isKanPassword"
                class="color-ff"
                style="font-size: 12px; cursor: pointer"
                >{{ isKanPassword ? '隐藏' : '查看' }}</span
              >
            </li>
            <li>
              <span>装修时间：</span>
              <span>{{ details.fang_xiu_time }}</span>
            </li>
            <li>
              <span>店面钥匙：</span>
              <span>{{ isKey ? details.yaoshi_dian : "***" }}</span>
              <span
                v-if="details.locking_status == 0"
                @click="isKey = !isKey"
                class="color-ff"
                style="font-size: 12px; cursor: pointer"
                >{{ isKey ? '隐藏' : '查看' }}</span
              >
            </li>
            <li>
              <span>入住时间：</span>
              <span>{{ details.fang_zhu }}</span>
            </li>
            <li>
              <span>维护人姓名：</span>
              <span>{{ details.weihu_id ? details.weihu_id.name : "" }}</span>
            </li>
            
            <li>
              <span>维护人电话：</span>
              <span>{{ details.weihu_id ? details.weihu_id.mob : "" }}</span>
            </li>
            <li>
              <span>成交人姓名：</span>
              <span>{{ details.chengjiao_user_name }}</span>
            </li>
            <li>
              <span>成交人电话：</span>
              <span>{{ details.chengjiao_user_mobile }}</span>
            </li>
            <li>
              <span>开发商：</span>
              <span>{{ details.xiaoqu_developers }}</span>
            </li>

            <li>
              <span>业主费用：</span>
              <span>{{ isYeZhuPrice ? details.yezhu_prc : '***' }}</span>
              <span
                @click="isYeZhuPrice = !isYeZhuPrice"
                class="color-ff"
                style="font-size: 12px; cursor: pointer"
                >{{ isYeZhuPrice ? '隐藏' : '查看' }}</span
              >
            </li>
            <li>
              <span>小区物业：</span>
              <span>{{ details.wuye }}</span>
            </li>
            <li>
              <span>出租时长：</span>
              <span>{{ details.zu_type + '-' + details.zu_types }}</span>
            </li>
            <li class="flex">
              <div class="flex-l">
                <span>地址：</span>
                <span>{{ details.fang_address }}</span>
              </div>
              <img
                class="house-more-icon"
                @click="$refs.more.open(id, type)"
                src="../../assets/image/houseing/house56.png"
              />
            </li>
          </ul>
          <div class="housing_head_img flex">
            <div class="housing_head_img_left flexs">
              <img src="../../assets/image/houseing/rule1.jpg" alt="" />
              <span>{{ details.yezhu_name }}</span>
            </div>
            <div class="housing_head_img_btn center" @click="lookPhone">
              <template v-if="details.look_number"
                >查看联系方式(今日{{ details.look_number }}次)</template
              >
              <template v-else>听取录音获取联系方式</template>
            </div>
          </div>
        </div>
      </div>
      <!--housing-detail start-->
      <div class="housing-detail p-t-30">
        <!--housing-detail-left start-->
        <div class="housing-detail-left">
          
          <!--户型介绍 detail-introduce start-->
          <div class="detail-introduce">
            <div class="detail-tit">户型介绍</div>
            <ul class="introduce-ul m-t-20" v-if="details.fang_huxing">
              <li>
                <div class="li-con">
                  <div class="li-img">
                    <img :src="details.fang_huxing.image" />
                  </div>
                  <div class="li-txt">
                    <div class="li-c">{{ details.fang_huxing.huxing }}</div>
                    <div class="li-c">
                      面积{{ details.fang_huxing.mianji }}m²/{{
                        details.fang_huxing.chaoxiang
                      }}
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            <div
              class="detail-more"
              @click="$refs.introduce.open(details.xiaoqu_id)"
            >
              更多信息
            </div>
          </div>
          <!--户型介绍 detail-introduce end-->
          <!--房源简介 detail-about start-->
          <div class="detail-about m-t-40">
            <div class="detail-tit">房源简介</div>
            <div class="about-con m-t-30">
              <!-- <div class="img">
                <img src="../../assets/image/houseing/rule1.jpg" />
              </div> -->
              <div class="txt m-t-20" v-html="details.fang_content"></div>
            </div>
            <div class="detail-more" @click="$refs.more.open(id, type)">
              更多信息
            </div>
          </div>
          <!--房源简介 detail-about end-->
          <!--经纪人带看反馈 detail-look start-->
          <div class="detail-look">
            <div class="detail-tit flex p-b-20">
              经纪人带看反馈
              <el-button
                class="detail-look-btn"
                @click="$refs.look.open(details.fang_type, details.id)"
                >带看反馈</el-button
              >
            </div>
            <div
              class="look-item"
              v-for="(item, index) in details.daikan"
              :key="index"
            >
              <div class="hd flex">
                <div class="h-l flex">
                  <img class="look-img" :src="item.user_image" />
                  <div class="txt">
                    <div class="name">{{ item.user_name }}</div>
                    <div class="date m-t-5">最近带看{{ item.updatetime }}</div>
                  </div>
                </div>
                <!-- <img
                  class="look-icon"
                  src="../../assets/image/houseing/phone.png"
                /> -->
              </div>
              <div class="article m-t-10">{{ item.content }}</div>
            </div>

            <!-- <div class="detail-more">更多信息</div> -->
          </div>
          <!--经纪人带看反馈 detail-look end-->
          <!--周边配套 detail-matching start-->
          <div class="detail-matching m-t-40 p-b-30">
            <div class="detail-tit">周边配套</div>
            <div class="detail-map m-t-30">
              <div class="detail-mating">
                <div id="container" style="height: 540px"></div>
                <div class="detail-mating-box">
                  <div class="detail-mating-main">
                    <div class="detail-mating-head flexs">
                      <div
                        @click="changeMating(index)"
                        :class="{
                          active: matingHead[matingIndex].type == item.type,
                        }"
                        class="detail-mating-head-li"
                        v-for="(item, index) in matingHead"
                        :key="index"
                      >
                        {{ item.name }}
                      </div>
                    </div>
                    <ul class="detail-mating-list">
                      <li v-for="(item, index) in matingList" :key="index">
                        <div class="detail-mating-list-top flex">
                          <span>{{ item.name }}</span>
                          <span>{{ item.detail_info.distance }}m</span>
                        </div>
                        <div class="detail-mating-list-lu">
                          {{ item.address }}
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--周边配套 detail-matching end-->
        </div>
        <!--housing-detail-left end-->
        <!--housing-detail-right start-->
        <div class="housing-detail-right">
          <!--经纪人 detail-person start-->
          <div class="detail-person">
            <div class="detail-tit">经纪人</div>
            <div
              class="person-item"
              v-for="(item, index) in houseClientList"
              :key="index"
            >
              <img class="item-img" :src="item.image" />
              <div class="item-txt">
                <div class="name">{{ item.name }}</div>
                <div class="tags m-t-5 m-b-5">
                  <span class="tag" v-for="(em, ind) in item.type" :key="ind">{{
                    em
                  }}</span>
                </div>
                <div class="c">{{ item.text }}</div>
              </div>
              <img
                class="item-icon m-t-20"
                src="../../assets/image/houseing/phone.png"
              />
            </div>
          </div>
          <!--经纪人 detail-person end-->
          <!--detail-phone start-->
          <div class="detail-phone">
            <div class="phone-hd">
              <div
                @click="followIndex = index"
                class="li"
                :class="{ active: index == followIndex }"
                v-for="(item, index) in 2"
                :key="index"
              >
                {{ index == 0 ? "手机号跟进" : "楼栋号跟进" }}
              </div>
            </div>
            <div class="phone-bd" v-if="phoneList.length || floorList.length">
              <template v-if="followIndex == 0">
                <div
                  class="phone-item"
                  v-for="(item, index) in phoneList"
                  :key="index"
                >
                  <div class="hd flex">
                    <div class="h-l flex">
                      <img class="phone-img" :src="item.image" />
                      <div class="txt">
                        <div class="name">{{ item.name }}</div>
                        <div class="date m-t-5">{{ item.createtime }}</div>
                      </div>
                    </div>
                    <!-- <img
                      class="phone-icon"
                      src="../../assets/image/houseing/icon25.png"
                    /> -->
                  </div>
                  <div class="article m-t-10">{{ item.text }}</div>
                </div>
              </template>
              <template v-else>
                <div
                  class="phone-item"
                  v-for="(item, index) in floorList"
                  :key="index"
                >
                  <div class="hd flex">
                    <div class="h-l flex">
                      <img class="phone-img" :src="item.image" />
                      <div class="txt">
                        <div class="name">{{ item.name }}</div>
                        <div class="date m-t-5">{{ item.createtime }}</div>
                      </div>
                    </div>
                    <!-- <img
                      class="phone-icon"
                      src="../../assets/image/houseing/icon25.png"
                    /> -->
                  </div>
                  <div class="article m-t-10">{{ item.text }}</div>
                </div>
              </template>
              <div
                class="phone-more p-t-20 p-b-20"
                @click="$refs.phone.open(id, followIndex + 1)"
                v-if="
                  (phoneList.length && phoneList.length == 6) ||
                  (floorList.length && floorList.length == 6)
                "
              >
                <span class="m-r-10">查看更多</span
                ><img
                  class="more-icon"
                  src="../../assets/image/houseing/icon44.png"
                />
              </div>
            </div>
          </div>
          <!--detail-phone end-->
        </div>
        <!--housing-detail-right end-->
      </div>
      <!--housing-detail end-->
    </div>

    <!--锁定弹出 start-->
    <BuildLock ref="lock" @success="getDetails"></BuildLock>
    <!--锁定弹出 end-->

    <!--成交房源弹出 start-->
    <BuildDone @success="getContant" ref="done"></BuildDone>
    <!--成交房源弹出 end-->

    <!--该房源已成交，请尽快更改房源状态弹出 start-->

    <!--该房源已成交，请尽快更改房源状态弹出 end-->

    <!--删除弹出 start-->
    <BuildDelete ref="delete" @succeed="delSucceed"></BuildDelete>
    <!--删除弹出 end-->

    <!--带看反馈弹出 start-->
    <!-- <BuildLook ref="look"></BuildLook> -->
    <band-look ref="look" @success="getDetails"></band-look>
    <!--带看反馈弹出 end-->

    <!--查看楼栋号弹出 start-->
    <BuildView ref="view" @succeed="followSucceed"></BuildView>
    <!--查看楼栋号弹出 end-->

    <!--点击地址后面图标详细信息弹出 start-->
    <BuildMore ref="more"></BuildMore>
    <!--点击地址后面图标详细信息弹出 end-->

    <!--联系方式弹出 start-->
    <BuildContact
      ref="contact"
      @succeed="followSucceed"
      @close="followSucceed"
    ></BuildContact>
    <!--联系方式弹出 end-->

    <!--户型介绍更多弹出 start-->
    <BuildIntroduce ref="introduce"></BuildIntroduce>
    <!--户型介绍更多弹出 end-->

    <!--房源简介更多弹出 start-->
    <BuildHouse ref="house"></BuildHouse>
    <!--房源简介更多弹出 end-->

    <!--楼栋号跟进弹出 start-->
    <BuildFloor ref="floor"></BuildFloor>
    <!--楼栋号跟进弹出 end-->

    <!--手机号跟进更多弹出 start-->
    <BuildPhone ref="phone"></BuildPhone>
    <!--手机号跟进更多弹出 end-->
    <!-- 跟进弹框 S -->
    <BuildFollow ref="follow" @succeed="followSucceed"></BuildFollow>
    <!-- 跟进弹框 E -->
    <!-- 锁定 S -->
    <!-- <build-lock ref="lock"></build-lock> -->
    <!-- 锁定 E -->
    <!-- 录音 S -->
    <BuildRecord ref="record"></BuildRecord>
    <!-- 录音 E -->
     <!-- 上传信息 S -->
     <upload-pop ref="pop"></upload-pop>
    <!-- 上传信息 E -->
    
    <select-client ref="client"></select-client>
  </div>
</template>

<script>
import BuildView from "@/components/BuildView.vue";
import BuildDelete from "../../components/BuildDelete";
import BuildContact from "../../components/BuildContact";
import BuildIntroduce from "../../components/BuildIntroduce";
import BuildHouse from "../../components/BuildHouse";
import BuildPhone from "../../components/BuildPhone";
import BuildFloor from "../../components/BuildFloor";
import BuildDone from "../../components/BuildDone";
import BuildLock from "../../components/BuildLock";
import BuildMore from "../../components/BuildMore";
import BuildRecord from "../../components/BuildRecord";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
// import 'swiper/css/swiper.css'
import { mapGetters } from "vuex";
import BuildFollow from "../../components/BuildFollow.vue";
import BandLook from "../../components/BandLook.vue";
import UploadPop from '@/components/UploadPop.vue';
import Signature from '@/components/Signature.vue';
import SelectClient from '@/components/SelectClient.vue';

export default {
  components: {
    Swiper,
    SwiperSlide,
    BuildView,
    BuildDelete,
    BuildContact,
    BuildIntroduce,
    BuildHouse,
    BuildPhone,
    BuildFloor,
    BuildDone,
    BuildLock,
    BuildMore,
    BuildFollow,
    BuildRecord,
    BandLook,
    UploadPop, Signature, SelectClient},
  computed: {
    // 使用对象展开运算符将 getter 混入 computed 对象中
    ...mapGetters(["token", "city"]),
  },
  data() {
    return {
      id: null, //房源ID
      type: 1, //1出售2出租
      details: {}, //详情
      userInfo: {}, //
      swiperOption: {
        slidesPerView: 4,
        spaceBetween: 5,
        slidesPerGroup: 4,
        loop: false,
        loopFillGroupWithBlank: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },

      swiper: {
        loop: true,
        loopFillGroupWithBlank: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      center: {
        lng: "",
        lat: "",
      },
      followIndex: 0, //0手机1楼栋号
      houseClientList: [], //经纪人列表
      phoneList: [], //手机号跟进
      floorList: [], //
      matingIndex: 0, //
      isMating: false, //
      matingHead: [
        {
          name: "交通",
          type: "公交",
        },
        {
          name: "教育",
          type: "学校",
        },
        {
          name: "医疗",
          type: "医院",
        },
        {
          name: "生活",
          type: "生活",
        },
        {
          name: "休闲",
          type: "休闲",
        },
      ],
      zuGradeList: [
        {
          value: "A",
          label: "A",
        },
        {
          value: "B",
          label: "B",
        },
        {
          value: "C",
          label: "C",
        },
        {
          value: "D",
          label: "D",
        },
      ],
      matingList: [], //配套
      isKey: false, //查看店面钥匙
      isKanFangType:false,//看房方式
      isKanPassword:false,//看房密码
      isYeZhuPrice:false,//业主费用
      lou: "",
      dan: "",
      hao: "",
      orientation: "",
      yuanyin:'',//修改原因
      clearTime: null, //锁定时间
      time: 0,
      isMore:true,//更多
      contract:{}
    };
  },
  methods: {
    //获取合同信息
    getContant () {
      this.$axios.contract({contract_type:this.type}).then(res => {
        this.contract = res.data
        this.$refs.pop.open(res.data.number, this.id)
      })
    },
    //锁定
    lock() {
      if (this.details.locking_status == 0) {
        this.$refs.lock.open(this.type, this.id);
      } else if (this.details.locking_status == 2) {
        this.$axios.quS({ fang_id: this.id }).then((res) => {
          this.$message({
            message: res.msg,
            type: "success",
          });
          this.getDetails();
        });
      }
    },
    //成交
    bargain() {
      if (this.details.locking_status != 0) {
        this.$refs.done.open(this.type, this.id);
      }
    },
    onSubmitlevel() {
      this.$axios
        .editZuHouse({
          id: this.id,
          fang_level: this.details.fang_level,
        })
        .then((res) => {
          this.$message({
            message: res.msg,
            type: "success",
          });
        });
    },
    onSubmitFool() {
      if (!this.lou) return this.$message.error("请输入几号楼");
      if (!this.dan) return this.$message.error("请输入几单元");
      if (!this.hao) return this.$message.error("请输入几室");
      if (!this.orientation) return this.$message.error("请输入朝向");
      if (!this.yuanyin) return this.$message.error("请输入修改原因");
      this.$axios
        .editZuHouse({
          id: this.id,
          xiaoqu_loudong:
            this.lou + "," + this.dan + "," + this.hao + "," + this.orientation,
            yuanyin:this.yuanyin
        })
        .then((res) => {
          this.$message({
            message: res.msg,
            type: "success",
          });
        });
    },
    //纠错价格
    onSubmitPrice() {
      if (!this.details.can_price) return;
      this.$axios
        .editZuHouse({
          can_price: this.details.can_price,
          id: this.id,
        })
        .then((res) => {
          this.$message({
            message: res.msg,
            type: "success",
          });
        });
    },
    //查看楼栋号
    lookBuilding() {
      if (this.details.fang_type == 1) {
        if (this.userInfo.lou_sub == 1) {
          this.$refs.follow.open(this.id, 2, this.details.shou_lou_id, 1);
        } else {
          this.$refs.view.open(this.id, 1);
        }
      } else {
        if (this.userInfo.zu_lou_sub == 1) {
          this.$refs.follow.open(this.id, 2, this.details.shou_lou_id, 2);
          // this.$refs.contact.open(this.id, 2, this.details.shou_lou_id, 2);
        } else {
          this.$refs.view.open(this.id, 2);
        }
      }
    },
    //删除成功
    delSucceed() {
      this.$router.go(-1);
    },
    //填完跟进记录
    followSucceed() {
      this.getuserInfo();
      this.getDetails();
      this.getPhoneList();
      this.gethouseLouLook();
    },
    //查看联系方式
    lookPhone() {
      if (!this.details.look_number) {
        this.$axios
          .soundsNum({ fang_id: this.id })
          .then(() => {
            if (this.details.fang_type == 1) {
              if (this.userInfo.house_sub == 1) {
                this.$refs.follow.open(this.id, 1, this.details.shou_lou_id, 1);
              } else {
                this.$refs.contact.open(
                  this.id,
                  1,
                  this.details.shou_mobile_id,
                  1
                );
              }
            } else {
              if (this.userInfo.zu_house_sub == 1) {
                this.$refs.follow.open(this.id, 1, this.details.shou_lou_id, 2);
              } else {
                this.$refs.contact.open(
                  this.id,
                  1,
                  this.details.shou_mobile_id,
                  2
                );
              }
            }
          })
          .catch(() => {
            this.$refs.record.open(this.id);
          });
        return;
      }
      if (this.details.fang_type == 1) {
        if (this.userInfo.house_sub == 1) {
          this.$refs.follow.open(this.id, 1, this.details.shou_lou_id, 1);
        } else {
          this.$refs.contact.open(this.id, 1, this.details.shou_mobile_id, 1);
        }
      } else {
        if (this.userInfo.zu_house_sub == 1) {
          this.$refs.follow.open(this.id, 1, this.details.shou_lou_id, 2);
        } else {
          this.$refs.contact.open(this.id, 1, this.details.shou_mobile_id, 2);
        }
      }

      //
    },
    //跳转详情页面
    hrefEdit() {
      this.$router.push({
        path: this.type == 1 ? "/editHousing" : "editRentHousing",
        query: { id: this.id, type: this.type },
      });
    },
    prev(swiper) {
      this.$refs[swiper].$swiper.slidePrev();
    },
    next(swiper) {
      this.$refs[swiper].$swiper.slideNext();
    },
    //是否加入黑名单
    changeHei() {
      this.$confirm("是否加入黑名单?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios
            .fangHei({
              id: this.id,
              fang_type: this.type, //1=出售2=出租
            })
            .then((res) => {
              this.$message({
                message: res.msg,
                type: "success",
              });
              // this.getDetails();
              this.$router.go(-1);
            });
        })
        .catch(() => {});
    },
    //是否关注
    changeColl() {
      this.$axios
        .fangGuanZhu({
          id: this.id,
          type: this.type,
        })
        .then((res) => {
          this.$message({
            message: res.msg,
            type: "success",
          });
          this.getDetails();
        });
    },
    timeFun(totalSeconds) {
      var days = Math.floor(totalSeconds / (60 * 60 * 24));
      //取模（余数）
      var modulo = totalSeconds % (60 * 60 * 24);
      //小时数
      var hours = Math.floor(modulo / (60 * 60));
      if (days > 0) {
        hours = hours + days * 24;
      }
      modulo = modulo % (60 * 60);
      //分钟
      var minutes = Math.floor(modulo / 60);
      //秒
      var seconds = modulo % 60;
      if (hours < 10) {
        hours = "0" + hours;
      }
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      if (seconds < 10) {
        seconds = "0" + seconds;
      }
      return hours + ":" + minutes + ":" + seconds;
    },
    //倒计时
    showTime(totalSeconds) {
      this.time = this.timeFun(totalSeconds);
      this.clearTime = setInterval(() => {
        if (totalSeconds == 0) {
          this.getDetails();
          this.details.locking_status = 0;
          clearInterval(this.clearTime);
        } else {
          totalSeconds--;
          this.time = this.timeFun(totalSeconds);
        }
      }, 1000);
    },
    //获取房源详情
    getDetails() {
      this.$axios[this.type == 1 ? "houseDetail" : "houseZuDetail"]({
        id: this.id,
      }).then((res) => {
        clearInterval(this.clearTime);
        res.data.locking_status == 1 && res.data.sou_time ? this.showTime(res.data.sou_time) : "";
        this.details = res.data;
        this.center.lng = res.data.fang_jingwei
          ? res.data.fang_jingwei.split(",")[0]
          : "";
        this.center.lat = res.data.fang_jingwei
          ? res.data.fang_jingwei.split(",")[1]
          : "";

        let xiaoqu_loudongs = res.data.xiaoqu_loudongs
          ? res.data.xiaoqu_loudongs.split(",")
          : [];
        this.lou = xiaoqu_loudongs[0];
        this.dan = xiaoqu_loudongs[1];
        this.hao = xiaoqu_loudongs[2];
        this.orientation = xiaoqu_loudongs[3];

        // eslint-disable-next-line no-undef
        var center = new TMap.LatLng(res.data.lat, res.data.lng);
        // eslint-disable-next-line no-undef
        var map = new TMap.Map("container", {
          rotation: 20, //设置地图旋转角度
          pitch: 30, //设置俯仰角度（0~45）
          zoom: 15, //设置地图缩放级别
          center: center, //设置地图中心点坐标
        });
        // eslint-disable-next-line no-unused-vars, no-undef
        var marker = new TMap.MultiMarker({
          map: map,
          styles: {
            // 点标记样式
            // eslint-disable-next-line no-undef
            marker: new TMap.MarkerStyle({
              width: 20, // 样式宽
              height: 30, // 样式高
              anchor: { x: 10, y: 30 }, // 描点位置
            }),
          },
          geometries: [
            // 点标记数据数组
            {
              // 标记位置(纬度，经度，高度)
              position: center,
              id: "marker",
            },
          ],
        });

        this.getuserInfo();
        this.getMap();
      });
    },
    //点击标注点
    infoWindowOpen() {
      this.isMating = !this.isMating;
    },
    //切换
    changeMating(index) {
      this.matingIndex = index;
      this.getMap();
    },
    getMap() {
      this.$axios
        .map1({
          bank: this.matingHead[this.matingIndex].type,
          location:
            this.details.fang_jingwei.split(",")[1] +
            "," +
            this.details.fang_jingwei.split(",")[0],
        })
        .then((res) => {
          this.matingList = res.data.results;
        });
    },
    //获取经纪人信息
    gethouseClient() {
      this.$axios.houseClient({ id: this.id }).then((res) => {
        this.houseClientList = res.data;
      });
    },
    //手机号跟进
    getPhoneList() {
      this.$axios.houseMobileLook({ id: this.id, pageNum: 6 }).then((res) => {
        this.phoneList = res.data.data;
      });
    },
    //楼栋号跟进
    gethouseLouLook() {
      this.$axios.houseLouLook({ id: this.id, pageNum: 6 }).then((res) => {
        this.floorList = res.data.data;
      });
    },
    getuserInfo() {
      this.$axios.userInfo().then((res) => {
        this.userInfo = res.data;

        if (res.data.house_sub == 1 || res.data.zu_house_sub == 1) {
          this.$refs.follow.open(
            res.data.fang_id,
            1,
            res.data.log_id,
            res.data.house_sub == 1 ? 1 : 2
          );
        } else if (res.data.lou_sub == 1 || res.data.zu_lou_sub == 1) {
          this.$refs.follow.open(
            res.data.fang_id,
            2,
            res.data.log_id,
            res.data.lou_sub == 1 ? 1 : 2
          );
        }
      });
    },
  },
  created() {
    this.id = this.$route.query.id;
    this.type = this.$route.query.type;
    this.getDetails();
    this.gethouseClient();
    this.getPhoneList();
    this.gethouseLouLook();
  },
};
</script>

<style lang="less" scoped>
.house-more-icon {
  width: 7px;
  height: 13px;
  cursor: pointer;
}
.swipers {
  .swiper-button-next {
    background: #171515 url("../../assets/image/houseing/house38.png") no-repeat
      center;
    width: 30px;
    height: 100px;
    right: 0px;
    top: 0;
    margin: 0;
  }
  .swiper-button-next:after {
    display: none;
  }
  .swiper-button-prev {
    background: #171515 url("../../assets/image/houseing/house37.png") no-repeat
      center;
    width: 30px;
    height: 100px;
    left: 0px;
    top: 0;
    margin: 0;
  }
  .swiper-button-prev:after {
    display: none;
  }
}

.slide-icon {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.slide-icon img {
  width: 28px;
  height: 28px;
}

.housing-detail {
  display: flex;
  justify-content: space-between;
  .housing-detail-left {
    width: 740px;
    .detail-mating {
      height: 540px;
      position: relative;
      .bm-view {
        width: 100%;
        height: 100%;
      }
      .detail-mating-box {
        width: 100%;
        position: absolute;
        bottom: 20px;
        left: 0;
        padding: 0 20px;
        z-index: 1998;
        .detail-mating-main {
          padding: 0 20px;
          background: #ffffff;
          border-radius: 4px;
          .detail-mating-head {
            .detail-mating-head-li {
              height: 60px;
              cursor: pointer;
              margin-right: 40px;
              line-height: 60px;
              color: #333333;
              font-size: 18px;
            }
            .active {
              color: #3273f6;
              font-size: 18px;
              font-weight: bold;
            }
          }
          .detail-mating-list {
            li {
              padding: 20px 0;
              border-top: 1px solid #ebebeb;
              .detail-mating-list-top {
                span {
                  color: #333333;
                  font-size: 16px;
                }
              }
              .detail-mating-list-lu {
                color: #999999;
                font-size: 14px;
                margin-top: 10px;
              }
            }
          }
        }
      }
    }
  }
  .housing-detail-right {
    width: 420px;
  }
  .detail-tit {
    font-size: 22px;
    font-weight: 600;
  }
  .detail-more {
    width: 420px;
    height: 56px;
    background: #f5f5f5;
    cursor: pointer;
    font-size: 20px;
    color: #89b0ff;
    display: block;
    text-align: center;
    line-height: 56px;
    margin: 25px auto;
  }
  .detail-look {
    .detail-look-btn {
      width: 120px;
      height: 40px;
      background: #d8e5ff;
      border-radius: 4px;
      font-size: 18px;
      color: #3273f6;
      border: none;
      outline: none;
    }
    .look-item {
      padding: 10px 0px;
      .hd {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .look-img {
        display: block;
        width: 40px;
        height: 40px;
        border-radius: 100%;
      }
      .txt {
        margin-left: 10px;
      }
      .name {
        font-size: 16px;
      }
      .date {
        font-size: 12px;
        color: #999;
      }
      .look-icon {
        width: 36px;
        height: 36px;
      }
      .article {
        font-size: 14px;
        color: #666;
        line-height: 20px;
      }
    }
  }
  .detail-introduce {
    .introduce-ul {
      display: flex;
      flex-wrap: wrap;
      li {
        width: 50%;
      }
      .li-con {
        margin: 10px 10px 10px 10px;
      }
      .li-img {
        width: 360px;
        height: 270px;
        border: 1px solid #ebebeb;
      }
      .li-img img {
        display: block;
        width: 100%;
        height: 100%;
      }
      .li-txt {
        margin: 10px 0px 0 0;
      }
      .li-c {
        font-size: 18px;
        color: #666;
        line-height: 1.6;
      }
    }
  }
  .detail-about {
    .about-con {
      .img img {
        width: 100%;
        display: block;
        border-radius: 4px;
      }
      .txt {
        font-size: 16px;
        color: #666;
        line-height: 1.6;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        overflow: hidden;
      }
    }
  }
  .detail-person {
    .person-item {
      box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.08);
      border-radius: 4px;
      padding: 20px;
      margin: 20px 0px;
      display: flex;
      .item-img {
        display: block;
        width: 64px;
        height: 64px;
        border-radius: 100%;
      }
      .item-icon {
        width: 36px;
        height: 36px;
      }
      .item-txt {
        width: calc(100% - 115px);
        margin-left: 15px;
      }
      .name {
        font-size: 18px;
        font-weight: 600;
      }
      .tags {
        display: flex;
        flex-wrap: wrap;
        margin-left: -5px;
        margin-right: -5px;
      }
      .tag {
        background: #d8e5ff;
        border-radius: 2px;
        padding: 0px 5px;
        font-size: 14px;
        color: #3273f6;
        margin: 5px;
        height: 24px;
        display: flex;
        align-items: center;
        font-size: 14px;
      }
      .c {
        font-size: 15px;
        color: #999;
      }
    }
  }
  .detail-phone {
    .phone-hd {
      display: flex;
      justify-content: space-around;
      padding: 25px 0px;
      .li {
        font-size: 22px;
        position: relative;
        cursor: pointer;
      }
      .active {
        font-weight: 600;
        color: #3273f6;
      }
      .active:after {
        width: 24px;
        height: 2px;
        position: absolute;
        bottom: -15px;
        left: 50%;
        margin-left: -12px;
        display: block;
        content: "";
        background: #3273f6;
      }
    }
    .phone-bd {
      box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.06);
      border-radius: 4px;
      padding: 10px 20px;
      .phone-item {
        padding: 10px 0px;
        .hd {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .phone-img {
          display: block;
          width: 40px;
          height: 40px;
          border-radius: 100%;
        }
        .txt {
          margin-left: 10px;
        }
        .name {
          font-size: 16px;
        }
        .date {
          font-size: 12px;
          color: #999;
        }
        .phone-icon {
          width: 28px;
          height: 28px;
        }
        .article {
          font-size: 14px;
          color: #666;
          line-height: 20px;
        }
      }
      .phone-more {
        color: #3273f6;
        font-size: 16px;
        text-align: center;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        .more-icon {
          width: 8px;
          height: 14px;
        }
      }
    }
  }
}

.housing_nav_time {
  color: #666666;
  font-size: 18px;
}
.housing_nav_name {
  color: #333333;
  font-size: 24px;
}
.housing_nav_ul {
  li {
    height: 28px;
    padding: 0 10px;
    font-size: 14px;
    color: #ffffff;
    position: relative;
    border-radius: 3px;
    span {
      font-size: 14px;
    }
    img {
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }
    &:first-child {
      background: #e1ebff;
      span {
        color: #3273f6;
      }
    }
    &:nth-child(2) {
      background: linear-gradient(43deg, #3273f6, #689aff);
      span {
        color: #ffffff;
      }
    }
    &:nth-child(3) {
      padding: 0;
      cursor: pointer;
    }
  }
  .hei {
    color: #999999;
    border: 1px solid #cccccc;
  }
  .color-ff {
    color: #ff2121;
  }
}
.housing_nav_coll {
  img {
    width: 18px;
    height: 18px;
    margin-right: 10px;
  }
  span {
    color: #666666;
    font-size: 14px;
  }
  cursor: pointer;
}
.housing_nav_number {
  color: #666666;
  font-size: 18px;
  margin-left: 40px;
}
.housing_head_left_video {
  width: 560px;
  height: 420px;
  position: relative;
  .swiper {
    height: 420px;
  }
  video {
    width: 100%;
    height: 100%;
    display: block;
  }
  .marking {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    img {
      width: 48px;
      height: 48px;
    }
  }
}
.housing_head_left_swiper {
  
  .swiper {
    width: 560px;
    height: 100px;
  }
}
.housing_head_left_more {
  cursor:pointer;
  margin-top: 10px;
  img {
    width: 16px;
    height: 10px;
    margin-left: 5px;
    transition: all 0.2s ease;
  }
  .rotate {
    transform: rotate(180deg);
  }
  div {
    color: #666666;
    font-size: 14px;
  }
}
.housing_head_image {
  width: 560px;
  padding: 0 34px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  li {
    cursor:pointer;
    margin: 10px 4px 0 0;
    img {
      width: 120px;
      height: 100px;
    }
    &:nth-child(4n) {
      margin-right: 0;
    }
  }
}
.housing_head_left_ul {
  width: 560px;
  margin-top: 20px;
  li {
    width: 130px;
    height: 56px;
    cursor: pointer;
    margin-right: 15px;
    border-radius: 4px;
    &:last-child {
      margin-right: 0;
    }
    img {
      width: 36px;
      height: 36px;
      margin-right: 10px;
    }
    span {
      color: #333333;
      font-size: 16px;
    }
  }
  .blue {
    background: #e1ebff;
  }
  .orange {
    background: #ffeee1;
  }
  .yellow {
    background: #fff7d7;
  }
  .gray {
    background: #f0f0f0;
  }
  .red {
    background: #ffd1cd;
  }
}
.housing_head {
  align-items: flex-start;
}
.housing_head_right {
  width: 600px;
  padding: 32px 20px;
  background: #ffffff;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  .housing_head_price_txt {
    span {
      flex-shrink: 0;
      &:first-child {
        color: #666666;
        font-size: 16px;
        margin-right: 15px;
      }
      &:last-child {
        color: #ff2121;
        font-size: 22px;
        font-weight: bold;
      }
    }
  }
  .housing_head_lou_number {
    span {
      color: #999999;
      font-size: 16px;
      &:last-child {
        color: #333333;
      }
    }
  }
  .housing_head_lou_look {
    color: #005aef;
    font-size: 14px;
    margin-left: 10px;
    cursor: pointer;
  }
  .housing_head_tag {
    li {
      color: #999999;
      font-size: 14px;
      height: 28px;
      padding: 0 10px;
      margin-right: 10px;
      margin-bottom: 10px;
      background: #f5f5f5;
      border-radius: 3px;
    }
  }
  .housing_head_area {
    li {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      span {
        &:first-child {
          color: #333333;
          font-size: 22px;
        }
        &:last-child {
          color: #666666;
          font-size: 16px;
          margin-top: 10px;
        }
      }
    }
  }
  .housing_head_time {
    padding: 20px;
    background: #e1ebff;
    border-radius: 4px;
    li {
      width: 50%;
      span {
        color: #666666;
        font-size: 16px;
        &:nth-child(2) {
          color: #333333;
        }
      }
      .color-ff {
        color: #ff2121;
      }
      &:nth-child(3),
      &:first-child {
        margin-top: 8px;
      }
    }
  }
  .housing_head_footer {
    background: none;
    padding: 0 0 30px 0;
    border-bottom: 1px solid #ebebeb;
    li {
      &:nth-child(3),
      &:first-child {
        margin-top: 0px;
      }
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
      span {
        &:first-child {
          color: #999999;
        }
      }
      &:last-child {
        width: 100%;
      }
    }
  }
  .housing_head_img_left {
    img {
      width: 48px;
      height: 48px;
      margin-right: 10px;
      border-radius: 50%;
    }
    span {
      color: #333333;
      font-size: 16px;
    }
  }
  .housing_head_img_btn {
    width: 384px;
    height: 48px;
    color: #ffffff;
    font-size: 18px;
    cursor: pointer;
    background: #3273f6;
    border-radius: 4px;
  }
}
</style>
