<template>
  <div class="examRule">
    <el-dialog
      title=""
      :show-close="false"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="600px"
    >
      <div class="p-l-20 p-r-20 p-t-20 p-b-20 view-pop">
        <div class="lock-pop">
          <div class="lock-close">
            <i class="el-icon-circle-close" @click="close"></i>
          </div>
          <div class="lock-tit p-t-30 p-b-20">请选择锁定类型</div>
          <div class="lock-flex p-t-40 p-b-40">
            <el-button @click="changeLock(index)" class="lock-btn" :class="{blue:index == 1}" v-for="(item,index) in 2" :key="index">{{ index == 0 ? '长期锁定' : '一般锁定' }}</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      type:null,//
      id:null,
      dialogVisible: false,
      content: "", //借还记录
      from:{
        type:'',
        types:'',
        fang_id:''
      }
    };
  },
  methods: {
    //锁定
    changeLock (index) {
      this.from.types = index == 0 ? '2' : '1'
      this.$axios.houseLocking(this.from).then(res=>{
        this.dialogVisible = false
        this.$message({
          message: res.msg,
          type: 'success'
        });
        this.$emit('success')
      })
    },
    //关闭
    close() {
      this.dialogVisible = false;
    },
    //打开
    open(type,id) {
      this.from.type = type
      this.from.fang_id = id
      this.dialogVisible = true;
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog__header,
/deep/ .el-dialog__body {
  padding: 0 !important;
}
/deep/ .el-dialog {
  border-radius: 8px;
  overflow: hidden;
}

// .examRule_main {
//   // line-height: 28px;
// }
.f-24 {
  font-size: 24px;
}
.view-pop {
  .view-text {
    background: #f5f5f5;
    border-radius: 8px;
    padding: 10px;
  }
  /deep/ .view-text .el-textarea__inner {
    border: none;
    outline: none;
    background: none;
    font-size: 18px;
  }
}

.lock-pop {
  width: 560px;
  .lock-tit {
    font-size: 22px;
    text-align: center;
  }
  .lock-close {
    display: flex;
    justify-content: flex-end;
    color: #666;
    font-size: 24px;
    cursor: pointer;
  }
  .lock-flex {
    display: flex;
    justify-content: space-around;
    .lock-btn {
      width: 200px;
      height: 56px;
      border-radius: 28px;
      border: 1px solid #3273f6;
      background: #fff;
      font-size: 18px;
      color: #3273f6;
    }
    .lock-btn.blue {
      background: #3273f6;
      color: #fff;
    }
  }
}

.interview_main_footer {
  padding: 40px 0 40px 0;
  /deep/ .el-button {
    width: 480px;
    height: 56px;
    color: #fff;
    font-size: 18px;
    background: #3273f6;
    border-radius: 28px;
  }
}
</style>
