<template>
  <div class="examRule">
    <el-dialog
      title=""
      :show-close="false"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="600px"
    >
      <div class="examRule_head flex">
        <span>删除房源</span>
        <i class="el-icon-circle-close" @click="close"></i>
      </div>
      <div class="p-l-20 p-r-20 p-t-20 p-b-20 view-pop">
        <div class="view-text">
          <el-input
          v-model="from.text"
            type="textarea"
            placeholder="请填写删除原因"
            rows="5"
          ></el-input>
        </div>
        <div class="interview_main_footer center">
          <el-button @click="submit">提交</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      from:{
        fang_id:'',
        text:'',
        type:''
      }
    };
  },
  methods: {
    submit () {
       if (!this.from.text) return this.$message.error('请填写删除原因');
      this.$axios.fangDel(this.from).then(res=>{
        this.$message({
          message: res.msg,
          type: 'success'
        });
         this.dialogVisible = false;
         this.$emit('succeed')
      })
    },
    //关闭
    close() {
      this.dialogVisible = false;
    },
    //打开
    open(id,type) {
      this.from.type = type
      this.from.fang_id = id
      this.dialogVisible = true;
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog__header,
/deep/ .el-dialog__body {
  padding: 0 !important;
}
/deep/ .el-dialog {
  border-radius: 8px;
  overflow: hidden;
}

// .examRule_main {
//   // line-height: 28px;
// }
.f-24 {
  font-size: 24px;
}
.view-pop {
  .view-text {
    background: #f5f5f5;
    border-radius: 8px;
    padding: 10px;
  }
  /deep/ .view-text .el-textarea__inner {
    border: none;
    outline: none;
    background: none;
    font-size: 18px;
  }
}
.interview_main_footer {
  padding: 40px 0 40px 0;
  /deep/ .el-button {
    width: 480px;
    height: 56px;
    color: #fff;
    font-size: 18px;
    background: #3273f6;
    border-radius: 28px;
  }
}
</style>
